toggleMenu = function(id) {
    const menuPopup = document.getElementById('menuPopup')
    const bodyArray = document.getElementsByTagName('body')
    const body = bodyArray && bodyArray[0]
    const display = menuPopup.style.display
    if (menuPopup.style.display === "none") {
        menuPopup.style.display = "flex";
        body.style.height = '100vh';
        body.style.overflowY = 'hidden';
    } else {
        menuPopup.style.display = "none";
        body.style.position = '';
        body.style.top = '';
        body.style.height = '';
        body.style.overflowY = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }
}