// let sliderControls = document.getElementById('controls-container');
//let villasControls = document.getElementById('villas-buttons-container');

const sliderPhotoContainer = document.getElementById('photo-list-slider')

if (sliderPhotoContainer) {
    const slider_photo_list = tns({
        container: '#photo-list-slider',
        controlsPosition: 'top',
        autoplayButton: false,
        autoplayPosition: 'bottom',
        gutter: 22,
        swipeAngle: false,
        speed: 400,
        "items": 1,
        //edgePadding: 50,
        mouseDrag: true,
        loop: true,
        prevButton: '.prevButton',

        //controlsContainer: villasControls,
    });
console.log('slider_photo_list', slider_photo_list)
}
