// let sliderControls = document.getElementById('controls-container');
let afishaControls = document.getElementById('afisha-buttons-container');

const sliderAfishaContainer = document.getElementsByClassName('afisha-slider')
if (sliderAfishaContainer && sliderAfishaContainer.length) {
    const slider_afisha = tns({
        container: '.afisha-slider',
        controlsPosition: 'bottom',
        autoplayButton: false,
        autoplayPosition: 'bottom',
        gutter: 22,
        swipeAngle: false,
        speed: 400,
        fixedWidth: 290,
        //edgePadding: 50,
        mouseDrag: true,
        loop: true,
        controlsContainer: afishaControls,
    });
console.log('slider_afisha', slider_afisha)
}
