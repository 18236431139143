
let sliderControls = document.getElementById('buttons-container');
const sliderContainer = document.getElementsByClassName('my-slider')

if (sliderContainer && sliderContainer.length) {
    const slider = tns({
        container: '.my-slider',
        items: 1,
        controlsPosition: 'bottom',
        autoplay: true,
        autoplayButton: false,
        autoplayPosition: 'bottom',
        controlsContainer: sliderControls,
        autoplayButtonOutput: false,
    });

}
    (function(w){
        var q=[
            ['setContext', 'TL-INT-crimeabreeze', 'ru'],
            ['embed', 'search-form', {container: 'tl-search-form'}]
        ];
        //var t=w.travelline=(w.travelline||{}),ti=t.integration=(t.integration||{});ti.__cq=ti.__cq?ti.__cq.concat(q):q;
        //if (!ti.__loader){ti.__loader=true;var d=w.document,p=d.location.protocol,s=d.createElement('script');s.type='text/javascript';s.async=true;s.src=(p=='https:'?p:'http:')+'//ibe.tlintegration.com/integration/loader.js';(d.getElementsByTagName('head')[0]||d.getElementsByTagName('body')[0]).appendChild(s);}
    })(window);



