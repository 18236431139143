// let sliderControls = document.getElementById('controls-container');
let villasControls = document.getElementById('villas-buttons-container');

const sliderVillasContainer = document.getElementById('villa-list-slider')

if (sliderVillasContainer) {
    const slider_villas_list = tns({
        container: '#villa-list-slider',
        controlsPosition: 'bottom',
        autoplayButton: false,
        autoplayPosition: 'bottom',
        gutter: 22,
        swipeAngle: false,
        speed: 400,
        fixedWidth: 290,
        //edgePadding: 50,
        mouseDrag: true,
        loop: true,
        controlsContainer: villasControls,
        responsive: {
            768: {
                fixedWidth: 290
            },
            576: {
                fixedWidth: 250,
            },
            320 : {
                fixedWidth: 177,
            }
        }
    });
console.log('slider_villas_list', slider_villas_list)
}
